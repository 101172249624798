"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const semantic_ui_react_1 = require("semantic-ui-react");
const InventoryItem = ({ item }) => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [item.item_type.name, item.item_type.wearable_size_type === 'INT' ?
            (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Label, { children: item.int_size }) :
            item.item_type.wearable_size_type === 'STANDARD' ?
                (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Label, { children: item.standard_size }) :
                null] }));
exports.default = InventoryItem;
