"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const FilteredNumberInput = ({ min = 0, max, disabled = false, value = null, onChange, label = undefined, }) => {
    const [quantity, setQuantity] = (0, react_1.useState)(value);
    (0, react_1.useEffect)(() => {
        if (onChange && quantity !== value) {
            onChange(quantity);
        }
    }, [onChange, quantity]);
    (0, react_1.useEffect)(() => {
        setQuantity(value);
    }, [value]);
    return ((0, jsx_runtime_1.jsx)(semantic_ui_react_1.Input, { label: label, type: "number", max: max, min: 0, disabled: disabled, value: quantity, error: quantity !== null && (quantity < min || (max !== undefined && quantity > max)), onChange: (e, { value }) => {
            const numValue = parseInt(value, 10);
            if (!isNaN(numValue)) {
                setQuantity(numValue);
                // TODO: Uncomment when debounce is implemented
                // if (min !== undefined && numValue < min) {
                //   setQuantity(min);
                // } else if (max !== undefined && numValue > max) {
                //   setQuantity(max);
                // } else {
                //   setQuantity(numValue);
                // }
            }
            else {
                setQuantity(null);
            }
        } }));
};
exports.default = FilteredNumberInput;
