"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const semantic_ui_react_1 = require("semantic-ui-react");
const date_fns_1 = require("date-fns");
const locale_1 = require("date-fns/locale");
const react_router_dom_1 = require("react-router-dom");
const EventCard = ({ event }) => {
    const { orgId } = (0, react_router_dom_1.useParams)();
    const isDatePassed = (date) => (0, date_fns_1.isAfter)(new Date(), (0, date_fns_1.parseISO)(date));
    const isDateOngoing = (date) => (0, date_fns_1.isBefore)(new Date(), (0, date_fns_1.parseISO)(date));
    return ((0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Card, { children: [(0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Card.Content, { children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Card.Header, { children: event.name }), (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Card.Meta, { children: [(0, jsx_runtime_1.jsxs)("span", { className: 'date', children: ["Du ", (0, date_fns_1.format)((0, date_fns_1.parseISO)(event.start_date), 'dd/MM/yyyy', { locale: locale_1.fr }), " au ", (0, date_fns_1.format)((0, date_fns_1.parseISO)(event.end_date), 'dd/MM/yyyy', { locale: locale_1.fr })] }), isDatePassed(event.start_date) && isDateOngoing(event.end_date) && (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Label, { color: 'green', children: "\u00C9v\u00E9nement en cours" }), isDateOngoing(event.start_date) && (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Label, { color: 'grey', children: "\u00C9v\u00E9nement futur" }), isDatePassed(event.end_date) && (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Label, { color: 'red', children: "\u00C9v\u00E9nement termin\u00E9" })] }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Card.Description, { children: event.description ? event.description : "Pas de description disponible." })] }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Card.Content, { extra: true, children: (0, jsx_runtime_1.jsxs)("p", { children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Icon, { name: "user" }), " ", event.users.length] }) }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Card.Content, { children: (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Button, { basic: true, color: "green", fluid: true, children: (0, jsx_runtime_1.jsxs)(react_router_dom_1.Link, { to: `/organizations/${orgId}/event/${event.id}`, style: { color: 'green' }, children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Icon, { name: "eye" }), " Informations"] }) }) })] }));
};
exports.default = EventCard;
