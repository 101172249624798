"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = __importStar(require("react"));
const semantic_ui_react_1 = require("semantic-ui-react");
const Api_types_1 = require("../../types/Api.types");
const UrlInput = react_1.default.lazy(async () => await Promise.resolve().then(() => __importStar(require('@components/UrlInput'))));
const EditItemTypeModal = ({ editItemType, onClose, itemType, open }) => {
    const [nameState, setNameState] = (0, react_1.useState)(itemType?.name ? itemType.name : '');
    const [supplierNameState, setSupplierNameState] = (0, react_1.useState)(itemType?.supplier_name ?? null);
    const [supplierUrlState, setSupplierUrlState] = (0, react_1.useState)(itemType?.supplier_url ?? '');
    const [hasCapacityState, setHasCapacityState] = (0, react_1.useState)(itemType?.has_capacity ?? false);
    const [hasExpiryDateState, setHasExpiryDateState] = (0, react_1.useState)(itemType?.has_expiry_date ?? false);
    const [canBeRentedState, setCanBeRentedState] = (0, react_1.useState)(itemType?.can_be_rented ?? false);
    const [supplierCommentState, setSupplierCommentState] = (0, react_1.useState)(itemType?.supplier_comment ?? '');
    const [wearableSizeTypeState, setWearableSizeTypeState] = (0, react_1.useState)(itemType?.wearable_size_type ?? null);
    react_1.default.useEffect(() => {
        if (itemType) {
            setNameState(itemType.name);
            setSupplierNameState(itemType.supplier_name);
            setSupplierUrlState(itemType.supplier_url ?? '');
            setHasCapacityState(itemType.has_capacity);
            setHasExpiryDateState(itemType.has_expiry_date);
            setCanBeRentedState(itemType.can_be_rented);
            setSupplierCommentState(itemType.supplier_comment ?? '');
            setWearableSizeTypeState(itemType.wearable_size_type);
        }
    }, [itemType]);
    if (!itemType) {
        return null;
    }
    const handleAddItem = () => {
        editItemType(itemType.id, {
            name: nameState,
            has_capacity: hasCapacityState,
            has_expiry_date: hasExpiryDateState,
            can_be_rented: canBeRentedState,
            wearable_size_type: wearableSizeTypeState,
            supplier_name: supplierNameState,
            supplier_comment: supplierCommentState,
            supplier_url: supplierUrlState,
        });
    };
    return ((0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Modal, { onClose: () => {
            onClose();
        }, open: open, children: [(0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Header, { icon: true, children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Icon, { name: "plus" }), (0, jsx_runtime_1.jsx)("h1", { children: "Ajouter un type d\u2018\u00E9l\u00E9ment \u00E0 l\u2018inventaire" })] }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Modal.Content, { children: (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Form, { children: (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Grid, { children: [(0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Grid.Row, { columns: 2, children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Column, { children: (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Form.Field, { children: [(0, jsx_runtime_1.jsx)("label", { children: "Nom g\u00E9n\u00E9rique" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Input, { type: "text", value: nameState, onChange: (e, { value }) => {
                                                        setNameState(value);
                                                    } })] }) }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Column, { children: (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Form.Field, { children: [(0, jsx_runtime_1.jsx)("label", { children: "Fournisseur" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Input, { type: "text", value: supplierNameState, onChange: (e, { value }) => {
                                                        setSupplierNameState(value);
                                                    } })] }) })] }), (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Grid.Row, { columns: 2, children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Column, { children: (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Form.Field, { children: [(0, jsx_runtime_1.jsx)("label", { children: "Commentaire fournisseur" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.TextArea, { value: supplierCommentState, onChange: (e, { value }) => {
                                                        setSupplierCommentState(value);
                                                    } })] }) }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Column, { children: (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Form.Field, { children: [(0, jsx_runtime_1.jsx)("label", { children: "URL fournisseur" }), (0, jsx_runtime_1.jsx)(UrlInput, { value: supplierUrlState, onChange: (url) => {
                                                        setSupplierUrlState(url);
                                                    } })] }) })] }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Divider, {}), (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Grid.Row, { columns: 3, children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Column, { children: (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Form.Field, { children: [(0, jsx_runtime_1.jsx)("label", { children: "Capacit\u00E9" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Checkbox, { toggle: true, checked: hasCapacityState, onChange: () => {
                                                        setHasCapacityState(!hasCapacityState);
                                                    } })] }) }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Column, { children: (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Form.Field, { children: [(0, jsx_runtime_1.jsx)("label", { children: "Date d\u2018expiration" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Checkbox, { toggle: true, checked: hasExpiryDateState, onChange: () => {
                                                        setHasExpiryDateState(!hasExpiryDateState);
                                                    } })] }) }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Column, { children: (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Form.Field, { children: [(0, jsx_runtime_1.jsx)("label", { children: "Peut \u00EAtre pr\u00E9t\u00E9 ou lou\u00E9" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Checkbox, { toggle: true, checked: canBeRentedState, onChange: () => {
                                                        setCanBeRentedState(!canBeRentedState);
                                                    } })] }) })] }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Divider, {}), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Row, { columns: 1, children: (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Column, { textAlign: "center", children: (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Header, { as: "h2", children: "Taille" }) }) }), (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Grid.Row, { columns: 3, children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Column, { children: (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Card, { centered: true, onClick: () => {
                                                setWearableSizeTypeState(Api_types_1.WearableSizeTypeEnum.INT);
                                            }, color: wearableSizeTypeState === Api_types_1.WearableSizeTypeEnum.INT ? 'blue' : undefined, style: {
                                                cursor: 'pointer',
                                                boxShadow: wearableSizeTypeState === 'INT' ? '0px 0px 15px rgba(33,133,208,1)' : 'none',
                                                border: wearableSizeTypeState === 'INT' ? '1px solid rgba(33,133,208,1)' : '1px solid rgba(0,0,0,0.1)',
                                            }, children: (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Card.Content, { textAlign: "center", children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Icon, { name: "sort numeric up", size: "large" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Divider, {}), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Card.Header, { children: "Taille chiffr\u00E9e" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Card.Description, { style: { fontSize: 'smaller' }, children: "Taille en 38 / 42 / ..." })] }) }) }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Column, { children: (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Card, { centered: true, onClick: () => {
                                                setWearableSizeTypeState(Api_types_1.WearableSizeTypeEnum.STANDARD);
                                            }, color: wearableSizeTypeState === Api_types_1.WearableSizeTypeEnum.STANDARD ? 'blue' : undefined, style: {
                                                cursor: 'pointer',
                                                boxShadow: wearableSizeTypeState === 'STANDARD' ? '0px 0px 15px rgba(33,133,208,1)' : 'none',
                                                border: wearableSizeTypeState === 'STANDARD' ? '1px solid rgba(33,133,208,1)' : '1px solid rgba(0,0,0,0.1)',
                                            }, children: (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Card.Content, { textAlign: "center", children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Icon, { name: "male", size: "large" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Divider, {}), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Card.Header, { children: "Taille standard" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Card.Description, { style: { fontSize: 'smaller' }, children: "Taille en XS / S / M / L / ..." })] }) }) }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Column, { children: (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Card, { centered: true, onClick: () => {
                                                setWearableSizeTypeState(null);
                                            }, color: wearableSizeTypeState === null ? 'blue' : undefined, style: {
                                                cursor: 'pointer',
                                                boxShadow: wearableSizeTypeState === null ? '0px 0px 15px rgba(33,133,208,1)' : 'none',
                                                border: wearableSizeTypeState === null ? '1px solid rgba(33,133,208,1)' : '1px solid rgba(0,0,0,0.1)',
                                            }, children: (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Card.Content, { textAlign: "center", children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Icon, { name: "ban", size: "large" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Divider, {}), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Card.Header, { children: "Pas de taille" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Card.Description, { style: { fontSize: 'smaller' }, children: "Les objets de ce type n\u2018ont pas de taille" })] }) }) })] })] }) }) }), (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Modal.Actions, { children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Button, { color: "black", onClick: onClose, children: "Annuler" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Button, { content: "Modifier", labelPosition: "right", icon: "pencil", disabled: !nameState, onClick: handleAddItem, positive: true })] })] }));
};
exports.default = EditItemTypeModal;
