"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeatureFlagSchema = exports.FeatureFlagEnum = void 0;
const zod_1 = require("zod");
var FeatureFlagEnum;
(function (FeatureFlagEnum) {
    FeatureFlagEnum["BATCHES"] = "BATCHES";
    FeatureFlagEnum["CLOTH_RENTALS"] = "CLOTH_RENTALS";
    FeatureFlagEnum["INVITE"] = "INVITE";
    FeatureFlagEnum["RENTALS"] = "RENTALS";
    FeatureFlagEnum["EVENTS"] = "EVENTS";
    FeatureFlagEnum["STOCKS"] = "STOCKS";
})(FeatureFlagEnum || (exports.FeatureFlagEnum = FeatureFlagEnum = {}));
exports.FeatureFlagSchema = zod_1.z.nativeEnum(FeatureFlagEnum);
