"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFeatureFlag = exports.FeatureFlagProvider = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const AuthProvider_1 = require("@context/AuthProvider");
const FeatureFlagContext = (0, react_1.createContext)({
    featureFlags: [],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setFeatureFlags: (featureFlags) => { },
});
const useFeatureFlag = () => (0, react_1.useContext)(FeatureFlagContext);
exports.useFeatureFlag = useFeatureFlag;
const FeatureFlagProvider = ({ children }) => {
    const [featureFlags, setFeatureFlags] = (0, react_1.useState)([]);
    const { accessToken } = (0, AuthProvider_1.useAuth)();
    (0, react_1.useEffect)(() => {
        const fetchFeatureFlags = async () => {
            try {
                const res = await fetch('/api/featureflag/userfeatureflag/', {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                const featureFlags = (await res.json());
                setFeatureFlags(featureFlags);
            }
            catch (error) {
                setFeatureFlags([]);
            }
        };
        if (accessToken) {
            void fetchFeatureFlags();
        }
    }, [accessToken]);
    return ((0, jsx_runtime_1.jsx)(FeatureFlagContext.Provider, { value: {
            featureFlags,
            setFeatureFlags,
        }, children: children }));
};
exports.FeatureFlagProvider = FeatureFlagProvider;
