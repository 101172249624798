"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const UrlInput = ({ value: initialValue = '', onChange, errorMessage = 'The URL is not valid.', placeholder = 'Enter URL', ...props }) => {
    const [url, setUrl] = (0, react_1.useState)(initialValue);
    const [error, setError] = (0, react_1.useState)(false);
    const validateUrl = (value) => {
        if (!value)
            return true;
        const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i');
        return pattern.test(value);
    };
    const handleChange = (e, data) => {
        const newValue = data.value ?? '';
        setUrl(newValue);
        const isValid = validateUrl(newValue);
        setError(!isValid);
        if (onChange)
            onChange(newValue, isValid);
    };
    return ((0, jsx_runtime_1.jsx)(semantic_ui_react_1.Form, { children: (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Form.Field, { error: error, children: [(0, jsx_runtime_1.jsx)("label", { children: "URL" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Input, { ...props, placeholder: placeholder, value: url, onChange: handleChange, error: error }), error && (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Message, { negative: true, children: errorMessage })] }) }));
};
exports.default = UrlInput;
