"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const semantic_ui_react_1 = require("semantic-ui-react");
const react_router_dom_1 = require("react-router-dom");
require("./Soon.css");
const Soon = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    return ((0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Segment, { placeholder: true, className: "segmentText", children: [(0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Header, { icon: true, className: "constructionText", children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Icon, { name: "clock outline" }), "Travaux en cours"] }), (0, jsx_runtime_1.jsx)("p", { children: "Cette section est en cours de d\u00E9veloppement et sera disponible prochainement." }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Button, { primary: true, onClick: () => navigate('/'), children: "Retour \u00E0 l'accueil" })] }));
};
exports.default = Soon;
