"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.APIError = void 0;
class APIError extends Error {
    status;
    statusText;
    data;
    constructor(status, statusText, data) {
        super(`Network response was not ok :) . (${status} ${statusText})`);
        this.status = status;
        this.statusText = statusText;
        this.data = data;
        this.name = 'APIError';
    }
}
exports.APIError = APIError;
