"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const translateClothName = (name) => {
    if (name === 'HELMET') {
        return 'Casque';
    }
    else if (name === 'GLOVES') {
        return 'Gants';
    }
    else if (name === 'SOFT_SHELL') {
        return 'Soft Shell';
    }
    else if (name === 'PANTS') {
        return 'Pantalon';
    }
    else if (name === 'SHOES') {
        return 'Chaussures';
    }
    else if (name === 'POLO') {
        return 'Polo';
    }
    else if (name === 'PULLOVER') {
        return 'Pull';
    }
    else if (name === 'PARKA') {
        return 'Parka';
    }
    else if (name === 'MULTI_POCKETS') {
        return 'Gilet multi-poches';
    }
    return 'Vêtement inconnu';
};
exports.default = translateClothName;
