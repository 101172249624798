"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const Api_types_1 = require("../../types/Api.types");
const semantic_ui_react_1 = require("semantic-ui-react");
const StatusFilterDropdown = ({ onChange, value = [Api_types_1.RentalStatusEnum.PENDING, Api_types_1.RentalStatusEnum.ACCEPTED, Api_types_1.RentalStatusEnum.PICKUP_LATE, Api_types_1.RentalStatusEnum.PICKED_UP, Api_types_1.RentalStatusEnum.RETURN_LATE] }) => {
    const [selectedStatus, setSelectedStatus] = (0, react_1.useState)(value);
    const tagOptions = [
        {
            text: 'En attente',
            value: Api_types_1.RentalStatusEnum.PENDING,
            label: { color: 'orange', empty: true, circular: true },
        },
        {
            text: 'Accepté',
            value: Api_types_1.RentalStatusEnum.ACCEPTED,
            label: { color: 'green', empty: true, circular: true },
        },
        {
            text: 'Prêt en retard',
            value: Api_types_1.RentalStatusEnum.PICKUP_LATE,
            label: { color: 'red', empty: true, circular: true },
        },
        {
            text: 'Récupéré',
            value: Api_types_1.RentalStatusEnum.PICKED_UP,
            label: { color: 'yellow', empty: true, circular: true },
        },
        {
            text: 'Retour en retard',
            value: Api_types_1.RentalStatusEnum.RETURN_LATE,
            label: { color: 'red', empty: true, circular: true },
        },
        {
            text: 'Retourné',
            value: Api_types_1.RentalStatusEnum.RETURNED,
            label: { color: 'green', empty: true, circular: true },
        },
    ];
    const renderLabel = (label) => ({
        color: label.label.color,
        content: label.text,
    });
    return ((0, jsx_runtime_1.jsx)(semantic_ui_react_1.Dropdown, { text: 'Statut', multiple: true, icon: 'filter', options: tagOptions, value: selectedStatus, renderLabel: renderLabel, onChange: (_event, data) => {
            setSelectedStatus(data.value);
            if (onChange) {
                onChange(data.value);
            }
        } }));
};
exports.default = StatusFilterDropdown;
