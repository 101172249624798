"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Api_types_1 = require("../types/Api.types");
const translateRentalStatus = (name, detail = false) => {
    if (name === Api_types_1.RentalStatusEnum.PENDING) {
        return 'En attente';
    }
    else if (name === Api_types_1.RentalStatusEnum.ACCEPTED) {
        return 'Accepté';
    }
    else if (name === Api_types_1.RentalStatusEnum.PICKUP_LATE) {
        return detail ? 'Retard de prêt' : 'En retard';
    }
    else if (name === Api_types_1.RentalStatusEnum.RETURN_LATE) {
        return detail ? 'Retard de retour' : 'En retard';
    }
    else if (name === Api_types_1.RentalStatusEnum.RETURNED) {
        return 'Retourné';
    }
    else if (name === Api_types_1.RentalStatusEnum.PICKED_UP) {
        return 'Récupéré';
    }
    return 'Statut inconnu';
};
exports.default = translateRentalStatus;
