"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const AddModal = ({ stockPlaces, itemTypes, addItem, onClose, open }) => {
    const [selectedStockPlace, setSelectedStockPlace] = (0, react_1.useState)(null);
    const [selectedItemType, setSelectedItemType] = (0, react_1.useState)(null);
    const [capacity, setCapacity] = (0, react_1.useState)(null);
    const [expiryDate, setExpiryDate] = (0, react_1.useState)(null);
    const [intSize, setIntSize] = (0, react_1.useState)(null);
    const [standardSize, setStandardSize] = (0, react_1.useState)(null);
    const [serialNumber, setSerialNumber] = (0, react_1.useState)(null);
    const [state, setState] = (0, react_1.useState)(null);
    //TODO: sell prince implementation
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [sellPrice, setSellPrice] = (0, react_1.useState)(null);
    //TODO: buy price implementation
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [buyPrice, setBuyPrice] = (0, react_1.useState)(null);
    //TODO: rent price implementation
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [rentPrice, setRentPrice] = (0, react_1.useState)(null);
    const handleAddItem = () => {
        addItem({
            int_size: intSize,
            standard_size: standardSize,
            expiry_date: expiryDate,
            capacity,
            serial_number: serialNumber,
            item_type: selectedItemType?.id ?? 0,
            stock_place: selectedStockPlace ?? 0,
            batch: null,
            state: String(state),
            sell_price: sellPrice,
            buy_price: buyPrice,
            rent_price: rentPrice,
        });
    };
    return ((0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Modal, { onClose: () => {
            onClose();
        }, open: open, children: [(0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Header, { icon: true, children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Icon, { name: "plus" }), (0, jsx_runtime_1.jsx)("h1", { children: "Ajouter un \u00E9l\u00E9ment \u00E0 l\u2018inventaire" })] }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Modal.Content, { children: (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Form, { children: [(0, jsx_runtime_1.jsxs)(semantic_ui_react_1.FormField, { children: [(0, jsx_runtime_1.jsx)("label", { children: "Type d\u2018item" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Dropdown, { placeholder: "S\u00E9lectionner un type", fluid: true, selection: true, options: itemTypes?.map((x) => ({ key: x.id, text: x.name, value: x.id })), onChange: (_e, { value }) => {
                                        setSelectedItemType(itemTypes?.find((x) => x.id === value) ?? null);
                                    } })] }), (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.FormField, { children: [(0, jsx_runtime_1.jsx)("label", { children: "Emplacement" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Dropdown, { placeholder: "S\u00E9lectionner un emplacement", fluid: true, selection: true, options: stockPlaces?.map((x) => ({ key: x.id, text: x.name, value: x.id })), onChange: (_e, { value }) => {
                                        setSelectedStockPlace(value);
                                    } })] }), (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.FormField, { disabled: !selectedItemType?.has_capacity, children: [(0, jsx_runtime_1.jsx)("label", { children: "Capacit\u00E9" }), (0, jsx_runtime_1.jsx)("input", { type: "number", onChange: (e) => {
                                        setCapacity(Number(e.target.value));
                                    } })] }), (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.FormField, { disabled: !selectedItemType?.has_expiry_date, children: [(0, jsx_runtime_1.jsx)("label", { children: "Date d\u2018expiration" }), (0, jsx_runtime_1.jsx)("input", { type: "date", onChange: (e) => {
                                        setExpiryDate(e.target.value);
                                    } })] }), (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Segment, { children: [(0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Grid, { columns: 2, relaxed: "very", stackable: true, children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.GridColumn, { children: (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.FormField, { disabled: !(selectedItemType?.wearable_size_type === 'INT'), children: [(0, jsx_runtime_1.jsx)("label", { children: "Taille num\u00E9rique" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Input, { type: "number", min: 20, max: 60, onChange: (e) => {
                                                            setIntSize(Number(e.target.value));
                                                        } })] }) }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.GridColumn, { verticalAlign: "middle", children: (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.FormField, { disabled: !(selectedItemType?.wearable_size_type === 'STANDARD'), children: [(0, jsx_runtime_1.jsx)("label", { children: "Taille standard" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Dropdown, { placeholder: "Select Size", fluid: true, selection: true, options: [
                                                            { key: 'XS', value: 'XS', text: 'XS' },
                                                            { key: 'S', value: 'S', text: 'S' },
                                                            { key: 'M', value: 'M', text: 'M' },
                                                            { key: 'L', value: 'L', text: 'L' },
                                                            { key: 'XL', value: 'XL', text: 'XL' },
                                                        ], onChange: (_e, { value }) => {
                                                            setStandardSize(value);
                                                        } })] }) })] }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Divider, { vertical: true, children: "Ou" })] }), (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.FormField, { children: [(0, jsx_runtime_1.jsx)("label", { children: "Num\u00E9ro de s\u00E9rie" }), (0, jsx_runtime_1.jsx)("input", { type: "text", onChange: (e) => {
                                        setSerialNumber(e.target.value);
                                    } })] }), (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.FormField, { children: [(0, jsx_runtime_1.jsx)("label", { children: "\u00C9tat" }), (0, jsx_runtime_1.jsx)("input", { type: "text", onChange: (e) => {
                                        setState(e.target.value);
                                    } })] })] }) }), (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Modal.Actions, { children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Button, { color: "black", onClick: onClose, children: "Annuler" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Button, { content: "Ajouter", labelPosition: "right", icon: "plus", onClick: handleAddItem, disabled: !selectedStockPlace || !selectedItemType || (selectedItemType?.has_capacity && !capacity) || (selectedItemType?.has_expiry_date && !expiryDate), positive: true })] })] }));
};
exports.default = AddModal;
