"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const EditableField = ({ value, label = '', icon, disabled = false, onSave }) => {
    const [isEditing, setIsEditing] = (0, react_1.useState)(false);
    const [currentValue, setCurrentValue] = (0, react_1.useState)(value ?? '');
    const handleInputChange = (event) => {
        setCurrentValue(event.target.value);
    };
    const handleSave = () => {
        onSave(currentValue);
        setIsEditing(false);
    };
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSave();
        }
    };
    return ((0, jsx_runtime_1.jsxs)("div", { children: [icon && ((0, jsx_runtime_1.jsx)(semantic_ui_react_1.Icon, { onClick: () => {
                    if (!disabled)
                        setIsEditing(true);
                }, name: icon })), label && ((0, jsx_runtime_1.jsxs)("label", { onClick: () => {
                    if (!disabled)
                        setIsEditing(true);
                }, children: [label, ":", ' '] })), isEditing ? ((0, jsx_runtime_1.jsx)(semantic_ui_react_1.Input, { autoFocus: true, value: currentValue, onChange: handleInputChange, onKeyPress: handleKeyPress, onClick: () => {
                    if (!disabled)
                        setIsEditing(true);
                }, action: (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Button, { color: "green", onClick: handleSave, children: "Save" }) })) : ((0, jsx_runtime_1.jsx)("span", { onClick: () => {
                    if (!disabled)
                        setIsEditing(true);
                }, style: { cursor: 'pointer' }, children: value }))] }));
};
exports.default = EditableField;
