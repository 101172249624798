"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WearableRentalPaginatedResultObject = exports.WearableRentalObject = exports.WearableRentalElementObject = exports.EventUserReportPaginatedResultObject = exports.EventUserReportObject = exports.EventPaginatedResultObject = exports.EventObject = exports.RentalPaginatedResultObject = exports.RentalObject = exports.BatchElementCreateObject = exports.BatchPaginatedResultObject = exports.BatchObject = exports.BatchCheckObject = exports.BatchRuleObject = exports.BatchElementObject = exports.ItemTypePaginatedResultObject = exports.StockPlacePaginatedResultObject = exports.ItemPaginatedResultObject = exports.OrganizationPaginatedResultObject = exports.UserRolePaginatedResultObject = exports.UserPaginatedResultObject = exports.PaginatedResultObject = exports.ItemObject = exports.StockPlaceObject = exports.ItemTypeObject = exports.WearableSizeTypeEnum = exports.UserObject = exports.UserRoleObject = exports.RoleObject = exports.RoleEnum = exports.OrganizationObject = exports.RentalStatusObject = exports.RentalStatusEnum = exports.ClothsTypeObject = exports.ClothsTypeEnum = exports.ClothsSizeObject = exports.ClothsSizeEnum = void 0;
const zod_1 = require("zod");
var ClothsSizeEnum;
(function (ClothsSizeEnum) {
    ClothsSizeEnum["XS"] = "XS";
    ClothsSizeEnum["S"] = "S";
    ClothsSizeEnum["M"] = "M";
    ClothsSizeEnum["L"] = "L";
    ClothsSizeEnum["XL"] = "XL";
    ClothsSizeEnum["XXL"] = "XXL";
    ClothsSizeEnum["XXXL"] = "XXXL";
})(ClothsSizeEnum || (exports.ClothsSizeEnum = ClothsSizeEnum = {}));
exports.ClothsSizeObject = zod_1.z.nativeEnum(ClothsSizeEnum).nullable();
var ClothsTypeEnum;
(function (ClothsTypeEnum) {
    ClothsTypeEnum["POLO"] = "POLO";
    ClothsTypeEnum["PULLOVER"] = "PULLOVER";
    ClothsTypeEnum["SOFT_SHELL"] = "SOFT_SHELL";
    ClothsTypeEnum["PANTS"] = "PANTS";
    ClothsTypeEnum["PARKA"] = "PARKA";
    ClothsTypeEnum["MULTI_POCKETS"] = "MULTI_POCKETS";
    ClothsTypeEnum["HELMET"] = "HELMET";
    ClothsTypeEnum["SHOES"] = "SHOES";
    ClothsTypeEnum["GLOVES"] = "GLOVES";
})(ClothsTypeEnum || (exports.ClothsTypeEnum = ClothsTypeEnum = {}));
exports.ClothsTypeObject = zod_1.z.nativeEnum(ClothsTypeEnum).nullable();
var RentalStatusEnum;
(function (RentalStatusEnum) {
    RentalStatusEnum["PENDING"] = "PENDING";
    RentalStatusEnum["ACCEPTED"] = "ACCEPTED";
    RentalStatusEnum["RETURNED"] = "RETURNED";
    RentalStatusEnum["PICKED_UP"] = "PICKED_UP";
    RentalStatusEnum["PICKUP_LATE"] = "PICKUP_LATE";
    RentalStatusEnum["RETURN_LATE"] = "RETURN_LATE";
    RentalStatusEnum["ERROR"] = "ERROR";
})(RentalStatusEnum || (exports.RentalStatusEnum = RentalStatusEnum = {}));
exports.RentalStatusObject = zod_1.z.nativeEnum(RentalStatusEnum);
exports.OrganizationObject = zod_1.z
    .object({
    id: zod_1.z.number().positive(),
    name: zod_1.z.string(),
    address: zod_1.z.string().nullable(),
    city: zod_1.z.string().nullable(),
    zip_code: zod_1.z.string().nullable(),
    phone_number: zod_1.z.string().nullable(),
    mail: zod_1.z.string().email(),
    siret: zod_1.z.string().nullable(),
    description: zod_1.z.string().nullable(),
})
    .strict();
var RoleEnum;
(function (RoleEnum) {
    RoleEnum["ADMIN"] = "ADMIN";
    RoleEnum["MANAGER"] = "MANAGER";
    RoleEnum["MEMBER"] = "MEMBER";
})(RoleEnum || (exports.RoleEnum = RoleEnum = {}));
exports.RoleObject = zod_1.z.nativeEnum(RoleEnum);
exports.UserRoleObject = zod_1.z
    .object({
    id: zod_1.z.number().positive(),
    role: exports.RoleObject,
    organization: exports.OrganizationObject,
    revoked_at: zod_1.z.string().nullable(),
    user: zod_1.z.number().positive(),
})
    .strict();
exports.UserObject = zod_1.z
    .object({
    id: zod_1.z.number().positive(),
    first_name: zod_1.z.string(),
    last_name: zod_1.z.string(),
    email: zod_1.z.string().email(),
    is_superuser: zod_1.z.boolean(),
    userroles: zod_1.z.array(exports.UserRoleObject),
    shirt_size: exports.ClothsSizeObject,
    pullover_size: exports.ClothsSizeObject,
    soft_shell_size: exports.ClothsSizeObject,
    pants_size: zod_1.z.number().positive().nullable(),
    parka_size: exports.ClothsSizeObject,
    multi_pockets_size: exports.ClothsSizeObject,
    helmet_size: exports.ClothsSizeObject,
    shoe_size: zod_1.z.number().positive().nullable(),
    glove_size: exports.ClothsSizeObject,
})
    .strict();
var WearableSizeTypeEnum;
(function (WearableSizeTypeEnum) {
    WearableSizeTypeEnum["STANDARD"] = "STANDARD";
    WearableSizeTypeEnum["INT"] = "INT";
})(WearableSizeTypeEnum || (exports.WearableSizeTypeEnum = WearableSizeTypeEnum = {}));
exports.ItemTypeObject = zod_1.z
    .object({
    id: zod_1.z.number().positive(),
    name: zod_1.z.string(),
    wearable_size_type: zod_1.z.nativeEnum(WearableSizeTypeEnum).nullable(),
    has_expiry_date: zod_1.z.boolean(),
    has_capacity: zod_1.z.boolean(),
    can_be_rented: zod_1.z.boolean(),
    organization: zod_1.z.number().positive(),
    supplier_name: zod_1.z.string().nullable(),
    supplier_url: zod_1.z.string().url().nullable(),
    supplier_comment: zod_1.z.string().nullable(),
})
    .strict();
exports.StockPlaceObject = zod_1.z
    .object({
    id: zod_1.z.number().positive(),
    name: zod_1.z.string(),
    organization: exports.OrganizationObject,
})
    .strict();
exports.ItemObject = zod_1.z
    .object({
    id: zod_1.z.number().positive(),
    int_size: zod_1.z.number().positive().nullable(),
    standard_size: exports.ClothsSizeObject,
    expiry_date: zod_1.z.string().nullable(),
    capacity: zod_1.z.number().positive().nullable(),
    quantity: zod_1.z.number().positive().nullable(),
    serial_number: zod_1.z.string().nullable(),
    item_type: exports.ItemTypeObject,
    stock_place: exports.StockPlaceObject,
    batch: zod_1.z.number().positive().nullable(),
    state: zod_1.z.string().nullable(),
    sell_price: zod_1.z.number().positive().nullable(),
    buy_price: zod_1.z.number().positive().nullable(),
    rent_price: zod_1.z.number().positive().nullable(),
})
    .strict();
exports.PaginatedResultObject = zod_1.z
    .object({
    count: zod_1.z.number().nonnegative(),
    next: zod_1.z.string().url().nullable(),
    previous: zod_1.z.string().url().nullable(),
    results: zod_1.z.array(zod_1.z.union([exports.UserObject, exports.UserRoleObject, exports.OrganizationObject])),
})
    .strict();
exports.UserPaginatedResultObject = exports.PaginatedResultObject.extend({
    results: zod_1.z.array(exports.UserObject),
}).strict();
exports.UserRolePaginatedResultObject = exports.PaginatedResultObject.extend({
    results: zod_1.z.array(exports.UserRoleObject),
}).strict();
exports.OrganizationPaginatedResultObject = exports.PaginatedResultObject.extend({
    results: zod_1.z.array(exports.OrganizationObject),
}).strict();
exports.ItemPaginatedResultObject = exports.PaginatedResultObject.extend({
    results: zod_1.z.array(exports.ItemObject),
}).strict();
exports.StockPlacePaginatedResultObject = exports.PaginatedResultObject.extend({
    results: zod_1.z.array(exports.StockPlaceObject),
}).strict();
exports.ItemTypePaginatedResultObject = exports.PaginatedResultObject.extend({
    results: zod_1.z.array(exports.ItemTypeObject),
}).strict();
exports.BatchElementObject = zod_1.z.object({
    id: zod_1.z.number().positive(),
    origin: exports.ItemObject,
    quantity_initial: zod_1.z.number().positive(),
    quantity_remaining: zod_1.z.number().positive(),
    created_at: zod_1.z.string(),
    updated_at: zod_1.z.string(),
}).strict();
exports.BatchRuleObject = zod_1.z.object({
    id: zod_1.z.number().positive(),
    name: zod_1.z.string(),
    reference: zod_1.z.string(),
    min_quantity: zod_1.z.number().positive().nullable(),
    max_quantity: zod_1.z.number().positive().nullable(),
    comment: zod_1.z.string(),
    organization: zod_1.z.number().positive(),
}).strict();
exports.BatchCheckObject = zod_1.z.object({
    id: zod_1.z.number().positive(),
    batch: zod_1.z.number().positive(),
    operator: exports.UserObject,
    is_valid: zod_1.z.boolean(),
    comment: zod_1.z.string(),
    date: zod_1.z.string(),
    content: zod_1.z.any(),
    rules: zod_1.z.any(),
}).strict();
exports.BatchObject = zod_1.z
    .object({
    id: zod_1.z.number().positive(),
    name: zod_1.z.string(),
    batch_rules: zod_1.z.array(exports.BatchRuleObject),
    checks: zod_1.z.array(exports.BatchCheckObject),
    elements: zod_1.z.array(exports.BatchElementObject),
})
    .strict();
exports.BatchPaginatedResultObject = exports.PaginatedResultObject.extend({
    results: zod_1.z.array(exports.BatchObject),
});
exports.BatchElementCreateObject = zod_1.z.object({
    item: zod_1.z.number().positive(),
    batch: zod_1.z.number().positive(),
    quantity: zod_1.z.number().positive(),
}).strict();
exports.RentalObject = zod_1.z.object({
    id: zod_1.z.number().positive(),
    name: zod_1.z.string(),
    comment: zod_1.z.string().nullable(),
    price: zod_1.z.number().positive().nullable(),
    event: zod_1.z.number().positive().nullable(),
    operator: zod_1.z.number().positive().nullable(),
    items: zod_1.z.array(exports.ItemObject),
    return_date: zod_1.z.string().nullable(),
    created_at: zod_1.z.string(),
    updated_at: zod_1.z.string(),
}).strict();
exports.RentalPaginatedResultObject = exports.PaginatedResultObject.extend({
    results: zod_1.z.array(exports.RentalObject),
});
exports.EventObject = zod_1.z.object({
    id: zod_1.z.number().positive(),
    name: zod_1.z.string(),
    description: zod_1.z.string().nullable(),
    created_at: zod_1.z.string(),
    updated_at: zod_1.z.string(),
    start_date: zod_1.z.string(),
    end_date: zod_1.z.string(),
    organization: zod_1.z.number().positive(),
    users: zod_1.z.array(exports.UserObject),
}).strict();
exports.EventPaginatedResultObject = exports.PaginatedResultObject.extend({
    results: zod_1.z.array(exports.EventObject),
});
exports.EventUserReportObject = zod_1.z.array(zod_1.z.object({
    user: zod_1.z.object({
        id: zod_1.z.number().positive(),
        first_name: zod_1.z.string(),
        last_name: zod_1.z.string(),
        email: zod_1.z.string().email(),
    }),
    events: zod_1.z.array(zod_1.z.object({
        id: zod_1.z.number().positive(),
        name: zod_1.z.string(),
        start_date: zod_1.z.string(),
        end_date: zod_1.z.string(),
        rental_count: zod_1.z.number().nonnegative(),
    })),
}).strict());
exports.EventUserReportPaginatedResultObject = exports.PaginatedResultObject.extend({
    results: zod_1.z.array(exports.EventUserReportObject),
}).strict();
exports.WearableRentalElementObject = zod_1.z.object({
    id: zod_1.z.number().positive(),
    events: zod_1.z.array(exports.EventObject),
    type: exports.ClothsTypeObject,
    status: exports.RentalStatusObject,
    standard_size: exports.ClothsSizeObject,
    int_size: zod_1.z.number().positive().nullable(),
    pickup_validated_at: zod_1.z.string().nullable(),
    pickup_validated_by: zod_1.z.number().positive().nullable(),
    return_validated_at: zod_1.z.string().nullable(),
    return_validated_by: zod_1.z.number().positive().nullable(),
    to_pickup_at: zod_1.z.string().nullable(),
    to_return_at: zod_1.z.string().nullable(),
    picked_up_at: zod_1.z.string().nullable(),
    returned_at: zod_1.z.string().nullable(),
    pickup_operator: exports.UserObject.nullable(),
    return_operator: exports.UserObject.nullable(),
    comment: zod_1.z.string().nullable(),
}).strict();
exports.WearableRentalObject = zod_1.z.object({
    user: exports.UserObject,
    organization: exports.OrganizationObject,
    elements: zod_1.z.array(exports.WearableRentalElementObject),
}).strict();
exports.WearableRentalPaginatedResultObject = exports.PaginatedResultObject.extend({
    results: zod_1.z.array(exports.WearableRentalObject),
}).strict();
