"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const semantic_ui_react_1 = require("semantic-ui-react");
const ItemTypeCard = ({ itemType, onEdit, onDelete }) => {
    let sizeIcon, sizeText;
    if (itemType.wearable_size_type === 'STANDARD') {
        sizeIcon = 'male';
        sizeText = 'Tailles standards';
    }
    else if (itemType.wearable_size_type === 'INT') {
        sizeIcon = 'calculator';
        sizeText = 'Tailles chiffrées';
    }
    else {
        sizeIcon = 'ban';
        sizeText = 'Pas de taille';
    }
    return ((0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Card, { raised: true, children: [(0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Card.Content, { children: [(0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Card.Header, { children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Icon, { name: "box" }), " ", itemType.name] }), itemType.supplier_name && ((0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Card.Meta, { children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Icon, { name: "industry" }), " ", itemType.supplier_name] })), (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Card.Description, { children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Icon, { name: itemType.has_capacity ? 'check circle outline' : 'times circle outline', color: itemType.has_capacity ? 'green' : 'red' }), " Contenance", (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Icon, { name: itemType.has_expiry_date ? 'check circle outline' : 'times circle outline', color: itemType.has_expiry_date ? 'green' : 'red' }), " Date d'expiration", (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Icon, { name: itemType.can_be_rented ? 'check circle outline' : 'times circle outline', color: itemType.can_be_rented ? 'green' : 'red' }), " Pr\u00EAt possible", (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Icon, { name: sizeIcon }), " ", sizeText] })] }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Card.Content, { extra: true, children: (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Button.Group, { widths: "2", children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Button, { icon: "pencil", basic: true, color: "blue", onClick: () => {
                                onEdit();
                            }, content: "Modifier" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Button, { icon: "trash", basic: true, color: "red", onClick: () => {
                                onDelete();
                            }, content: "Supprimer" })] }) })] }, itemType.id));
};
exports.default = ItemTypeCard;
