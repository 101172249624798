"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvitationLinkInformationObject = exports.InvitationLinkPaginatedResultObject = exports.InvitationLinkObject = void 0;
const Api_types_1 = require("./Api.types");
const zod_1 = require("zod");
exports.InvitationLinkObject = zod_1.z
    .object({
    created_at: zod_1.z.string(),
    id: zod_1.z.string().uuid(),
    is_valid: zod_1.z.boolean(),
    max_uses: zod_1.z.number(),
    organization: Api_types_1.OrganizationObject,
    remaining_uses: zod_1.z.number(),
    revoked_at: zod_1.z.string().nullable(),
    role: zod_1.z.nativeEnum(Api_types_1.RoleEnum),
    updated_at: zod_1.z.string(),
    valid_until: zod_1.z.string(),
})
    .strict();
exports.InvitationLinkPaginatedResultObject = Api_types_1.PaginatedResultObject.extend({
    results: zod_1.z.array(exports.InvitationLinkObject),
}).strict();
exports.InvitationLinkInformationObject = zod_1.z.object({
    organization: Api_types_1.OrganizationObject,
    role: zod_1.z.nativeEnum(Api_types_1.RoleEnum),
});
