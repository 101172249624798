"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatDate = exports.getClassName = exports.isAboveDelta = exports.getDate = exports.getFormatString = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const date_fns_1 = require("date-fns");
const locale_1 = require("date-fns/locale");
require("./DateLabel.css");
const getFormatString = (includeDate, includeTime, formatString) => {
    if (formatString)
        return formatString;
    let ret = includeDate ? 'EEE dd/MM/yyyy' : '';
    ret += includeTime && includeDate ? ' ' : '';
    ret += includeTime ? 'HH:mm' : '';
    return ret;
};
exports.getFormatString = getFormatString;
const getDate = (date, fallback) => {
    if (!date)
        return fallback;
    if (date instanceof Date)
        return date;
    return (0, date_fns_1.parseISO)(date) || fallback;
};
exports.getDate = getDate;
const isAboveDelta = (date, delta, fallback) => {
    if (!date)
        return false;
    const diff = (0, date_fns_1.differenceInDays)((0, exports.getDate)(date, fallback), new Date());
    return diff <= delta;
};
exports.isAboveDelta = isAboveDelta;
const getClassName = (date, dueDate, warningDaysDelta, alertDaysDelta, fallback) => {
    if (!dueDate)
        return '';
    if ((0, exports.isAboveDelta)(date, alertDaysDelta, fallback))
        return 'date-span-alert';
    if ((0, exports.isAboveDelta)(date, warningDaysDelta, fallback))
        return 'date-span-warning';
    return 'date-span-normal';
};
exports.getClassName = getClassName;
const formatDate = (date, formatString, includeTime, includeDate, locale, fallback) => {
    if (!date)
        return fallback;
    return (0, date_fns_1.format)((0, exports.getDate)(date, fallback), (0, exports.getFormatString)(includeDate, includeTime, formatString), { locale });
};
exports.formatDate = formatDate;
const DateLabel = ({ date, formatString = '', includeTime = true, includeDate = true, locale = locale_1.fr, fallback = '', dueDate = false, warningDaysDelta = 30, alertDaysDelta = 7 }) => {
    if (!date)
        return fallback;
    if (!includeDate && !includeTime)
        return fallback;
    return ((0, jsx_runtime_1.jsx)("span", { className: (0, exports.getClassName)(date, dueDate, warningDaysDelta, alertDaysDelta, fallback), children: (0, exports.formatDate)(date, formatString, includeTime, includeDate, locale, fallback) }));
};
exports.default = DateLabel;
