"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const semantic_ui_react_1 = require("semantic-ui-react");
const AuthProvider_1 = require("@context/AuthProvider");
const Home = () => {
    const { auth } = (0, AuthProvider_1.useAuth)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    (0, react_1.useEffect)(() => {
        if (auth) {
            navigate('/organizations');
        }
    }, [auth, navigate]);
    return ((0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Grid, { style: { padding: '1em' }, children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Row, { children: (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Image, { src: "/static/logo.png", size: "medium", centered: true }) }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Row, { centered: true, children: (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Header, { as: "h1", textAlign: "center", children: ["Bienvenue sur ", (0, jsx_runtime_1.jsx)("span", { style: { color: 'darkOrange' }, children: "Pumpkin" }), ", votre site de gestion d\u2018", (0, jsx_runtime_1.jsx)("span", { style: { color: 'darkOrange' }, children: "inventaire" }), " et de pr\u00E9paration de ", (0, jsx_runtime_1.jsx)("span", { style: { color: 'darkOrange' }, children: "mat\u00E9riel" })] }) }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Row, { centered: true, children: (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Header, { as: "h3", textAlign: "center", children: ["Pour acc\u00E9der aux fonctionnalit\u00E9s du site, vous devez vous ", (0, jsx_runtime_1.jsx)("span", { style: { color: 'purple' }, children: "connecter" }), " via le bouton en haut \u00E0 droite"] }) })] }));
};
exports.default = Home;
