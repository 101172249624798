"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const MoveModal = ({ item, stockPlaces, moveItem, onClose, open }) => {
    const [selectedStockPlace, setSelectedStockPlace] = (0, react_1.useState)(null);
    if (!item) {
        return null;
    }
    const handleMoveItem = () => {
        if (selectedStockPlace) {
            moveItem(item.id, selectedStockPlace);
            onClose();
        }
    };
    return ((0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Modal, { onClose: () => {
            onClose();
        }, open: open, children: [(0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Header, { icon: true, children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Icon, { name: "dolly" }), "D\u00E9placer ", item.item_type.name] }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Modal.Content, { children: (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Dropdown, { placeholder: "S\u00E9lectionner un emplacement", fluid: true, selection: true, options: stockPlaces?.filter((x) => x.id !== item.stock_place.id).map((x) => ({
                        key: x.id,
                        text: x.name,
                        value: x.id,
                    })), onChange: (_ignored, { value }) => {
                        setSelectedStockPlace(value);
                    } }) }), (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Modal.Actions, { children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Button, { color: "black", onClick: onClose, children: "Annuler" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Button, { content: "D\u00E9placer", labelPosition: "right", icon: "checkmark", onClick: handleMoveItem, disabled: !selectedStockPlace, positive: true })] })] }));
};
exports.default = MoveModal;
