"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const semantic_ui_react_1 = require("semantic-ui-react");
const Api_types_1 = require("../types/Api.types");
const ClothSizeDropdown = ({ onChange, value, disabled, fluid, placeholder = "Sélectionnez une taille" }) => {
    const options = Object.values(Api_types_1.ClothsSizeEnum).map((size) => ({ key: size, text: size, value: size }));
    return ((0, jsx_runtime_1.jsx)(semantic_ui_react_1.Dropdown, { placeholder: placeholder, fluid: fluid, selection: true, options: options, value: value || undefined, disabled: disabled, onChange: (_, data) => onChange(data.value) }));
};
exports.default = ClothSizeDropdown;
