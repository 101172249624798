"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const CopyToClipboardButton = ({ value }) => {
    const [copied, setCopied] = (0, react_1.useState)(false);
    const copyToClipboard = () => {
        void navigator.clipboard.writeText(value);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };
    return ((0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Button, { basic: true, animated: "vertical", style: { zIndex: 0, marginRight: '1px' }, onClick: copyToClipboard, children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Button.Content, { hidden: true, children: copied ? 'Copié !' : 'Copier' }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Button.Content, { visible: true, children: (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Icon, { name: copied ? 'check' : 'copy' }) })] }));
};
exports.default = CopyToClipboardButton;
