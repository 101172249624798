"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = __importStar(require("react"));
const semantic_ui_react_1 = require("semantic-ui-react");
const Api_types_1 = require("../../types/Api.types");
const react_router_dom_1 = require("react-router-dom");
const UrlInput = react_1.default.lazy(async () => await Promise.resolve().then(() => __importStar(require('../../component/UrlInput'))));
const AddItemTypeModal = ({ addItem, onClose, open }) => {
    const [name, setName] = (0, react_1.useState)('');
    const [supplierName, setSupplierName] = (0, react_1.useState)(null);
    const [supplierUrl, setSupplierUrl] = (0, react_1.useState)('');
    const [hasCapacity, setHasCapacity] = (0, react_1.useState)(false);
    const [hasExpiryDate, setHasExpiryDate] = (0, react_1.useState)(false);
    const [canBeRented, setCanBeRented] = (0, react_1.useState)(false);
    const [supplierComment, setSupplierComment] = (0, react_1.useState)('');
    const [wearableSizeType, setWearableSizeType] = (0, react_1.useState)(null);
    const { orgId } = (0, react_router_dom_1.useParams)();
    const handleAddItem = () => {
        addItem({
            name,
            has_capacity: hasCapacity,
            has_expiry_date: hasExpiryDate,
            can_be_rented: canBeRented,
            wearable_size_type: wearableSizeType,
            organization: Number(orgId),
            supplier_name: supplierName,
            supplier_comment: supplierComment,
            supplier_url: supplierUrl || null,
        });
    };
    return ((0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Modal, { onClose: () => {
            onClose();
        }, open: open, children: [(0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Header, { icon: true, children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Icon, { name: "plus" }), (0, jsx_runtime_1.jsx)("h1", { children: "Ajouter un type d\u2018\u00E9l\u00E9ment \u00E0 l\u2018inventaire" })] }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Modal.Content, { children: (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Form, { children: (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Grid, { children: [(0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Grid.Row, { columns: 2, children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Column, { children: (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Form.Field, { children: [(0, jsx_runtime_1.jsx)("label", { children: "Nom g\u00E9n\u00E9rique" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Input, { type: "text", value: name, onChange: (_e, { value }) => {
                                                        setName(value);
                                                    } })] }) }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Column, { children: (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Form.Field, { children: [(0, jsx_runtime_1.jsx)("label", { children: "Fournisseur" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Input, { type: "text", value: supplierName, onChange: (_e, { value }) => {
                                                        setSupplierName(value);
                                                    } })] }) })] }), (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Grid.Row, { columns: 2, children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Column, { children: (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Form.Field, { children: [(0, jsx_runtime_1.jsx)("label", { children: "Commentaire fournisseur" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.TextArea, { value: supplierComment, onChange: (_e, { value }) => {
                                                        setSupplierComment(value);
                                                    } })] }) }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Column, { children: (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Form.Field, { children: [(0, jsx_runtime_1.jsx)("label", { children: "URL fournisseur" }), (0, jsx_runtime_1.jsx)(UrlInput, { value: supplierUrl, onChange: (url) => {
                                                        setSupplierUrl(url);
                                                    } })] }) })] }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Divider, {}), (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Grid.Row, { columns: 3, children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Column, { children: (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Form.Field, { children: [(0, jsx_runtime_1.jsx)("label", { children: "Capacit\u00E9" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Checkbox, { toggle: true, checked: hasCapacity, onChange: () => {
                                                        setHasCapacity(!hasCapacity);
                                                    } })] }) }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Column, { children: (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Form.Field, { children: [(0, jsx_runtime_1.jsx)("label", { children: "Date d\u2018expiration" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Checkbox, { toggle: true, checked: hasExpiryDate, onChange: () => {
                                                        setHasExpiryDate(!hasExpiryDate);
                                                    } })] }) }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Column, { children: (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Form.Field, { children: [(0, jsx_runtime_1.jsx)("label", { children: "Peut \u00EAtre pr\u00E9t\u00E9 ou lou\u00E9" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Checkbox, { toggle: true, checked: canBeRented, onChange: () => {
                                                        setCanBeRented(!canBeRented);
                                                    } })] }) })] }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Divider, {}), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Row, { columns: 1, children: (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Column, { textAlign: "center", children: (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Header, { as: "h2", children: "Taille" }) }) }), (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Grid.Row, { columns: 3, children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Column, { children: (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Card, { centered: true, onClick: () => {
                                                setWearableSizeType(Api_types_1.WearableSizeTypeEnum.INT);
                                            }, color: wearableSizeType === Api_types_1.WearableSizeTypeEnum.INT ? 'blue' : undefined, style: {
                                                cursor: 'pointer',
                                                boxShadow: wearableSizeType === Api_types_1.WearableSizeTypeEnum.INT ? '0px 0px 15px rgba(33,133,208,1)' : 'none',
                                                border: wearableSizeType === Api_types_1.WearableSizeTypeEnum.INT ? '1px solid rgba(33,133,208,1)' : '1px solid rgba(0,0,0,0.1)',
                                            }, children: (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Card.Content, { textAlign: "center", children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Icon, { name: "sort numeric up", size: "large" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Divider, {}), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Card.Header, { children: "Taille chiffr\u00E9e" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Card.Description, { style: { fontSize: 'smaller' }, children: "Taille en 38 / 42 / ..." })] }) }) }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Column, { children: (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Card, { centered: true, onClick: () => {
                                                setWearableSizeType(Api_types_1.WearableSizeTypeEnum.STANDARD);
                                            }, color: wearableSizeType === Api_types_1.WearableSizeTypeEnum.STANDARD ? 'blue' : undefined, style: {
                                                cursor: 'pointer',
                                                boxShadow: wearableSizeType === Api_types_1.WearableSizeTypeEnum.STANDARD ? '0px 0px 15px rgba(33,133,208,1)' : 'none',
                                                border: wearableSizeType === Api_types_1.WearableSizeTypeEnum.STANDARD ? '1px solid rgba(33,133,208,1)' : '1px solid rgba(0,0,0,0.1)',
                                            }, children: (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Card.Content, { textAlign: "center", children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Icon, { name: "male", size: "large" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Divider, {}), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Card.Header, { children: "Taille standard" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Card.Description, { style: { fontSize: 'smaller' }, children: "Taille en XS / S / M / L / ..." })] }) }) }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Column, { children: (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Card, { centered: true, onClick: () => {
                                                setWearableSizeType(null);
                                            }, color: wearableSizeType === null ? 'blue' : undefined, style: {
                                                cursor: 'pointer',
                                                boxShadow: wearableSizeType === null ? '0px 0px 15px rgba(33,133,208,1)' : 'none',
                                                border: wearableSizeType === null ? '1px solid rgba(33,133,208,1)' : '1px solid rgba(0,0,0,0.1)',
                                            }, children: (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Card.Content, { textAlign: "center", children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Icon, { name: "ban", size: "large" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Divider, {}), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Card.Header, { children: "Pas de taille" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Card.Description, { style: { fontSize: 'smaller' }, children: "Les objets de ce type n\u2018ont pas de taille" })] }) }) })] })] }) }) }), (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Modal.Actions, { children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Button, { color: "black", onClick: onClose, children: "Annuler" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Button, { content: "Ajouter", labelPosition: "right", icon: "plus", disabled: !name, onClick: handleAddItem, positive: true })] })] }));
};
exports.default = AddItemTypeModal;
