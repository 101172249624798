// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../.yarn/__virtual__/css-loader-virtual-652b6c13f2/2/root/.yarn/berry/cache/css-loader-npm-7.1.2-7540f12884-10c0.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../.yarn/__virtual__/css-loader-virtual-652b6c13f2/2/root/.yarn/berry/cache/css-loader-npm-7.1.2-7540f12884-10c0.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.1);
        opacity: 0.7;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.constructionText {
    animation: pulse 2s infinite;
}

.segmentText {
    margin-top: 2em;
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/component/Soon.css"],"names":[],"mappings":"AAAA;IACI;QACI,mBAAmB;QACnB,UAAU;IACd;IACA;QACI,qBAAqB;QACrB,YAAY;IAChB;IACA;QACI,mBAAmB;QACnB,UAAU;IACd;AACJ;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB","sourcesContent":["@keyframes pulse {\n    0% {\n        transform: scale(1);\n        opacity: 1;\n    }\n    50% {\n        transform: scale(1.1);\n        opacity: 0.7;\n    }\n    100% {\n        transform: scale(1);\n        opacity: 1;\n    }\n}\n\n.constructionText {\n    animation: pulse 2s infinite;\n}\n\n.segmentText {\n    margin-top: 2em;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
