"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClothItemObject = void 0;
const Api_types_1 = require("./Api.types");
const zod_1 = require("zod");
exports.ClothItemObject = zod_1.z
    .object({
    cloth_type: Api_types_1.ClothsTypeObject,
    int_size: zod_1.z.number().positive().nullable(),
    standard_size: Api_types_1.ClothsSizeObject,
    quantity: zod_1.z.number().positive().nullable(),
    organization: Api_types_1.OrganizationObject,
})
    .strict();
