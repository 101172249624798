// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../.yarn/__virtual__/css-loader-virtual-652b6c13f2/2/root/.yarn/berry/cache/css-loader-npm-7.1.2-7540f12884-10c0.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../.yarn/__virtual__/css-loader-virtual-652b6c13f2/2/root/.yarn/berry/cache/css-loader-npm-7.1.2-7540f12884-10c0.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.site {
    display: grid;
    grid-template-rows: 1fr auto;
    min-height: 100vh;
}

.content-wrap {
    padding-bottom: 50px;
}

.footer {
    grid-row: 2;
    width: 100%;
    z-index: 1000;
    height: min-content;
}


.measurement-container {
    width: 100%;
    max-width: 512px; /* Largeur du SVG */
    margin: auto;
}

svg {
    width: 100%;
    height: auto;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,4BAA4B;IAC5B,iBAAiB;AACrB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,aAAa;IACb,mBAAmB;AACvB;;;AAGA;IACI,WAAW;IACX,gBAAgB,EAAE,mBAAmB;IACrC,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".site {\n    display: grid;\n    grid-template-rows: 1fr auto;\n    min-height: 100vh;\n}\n\n.content-wrap {\n    padding-bottom: 50px;\n}\n\n.footer {\n    grid-row: 2;\n    width: 100%;\n    z-index: 1000;\n    height: min-content;\n}\n\n\n.measurement-container {\n    width: 100%;\n    max-width: 512px; /* Largeur du SVG */\n    margin: auto;\n}\n\nsvg {\n    width: 100%;\n    height: auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
