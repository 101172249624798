// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../.yarn/__virtual__/css-loader-virtual-652b6c13f2/2/root/.yarn/berry/cache/css-loader-npm-7.1.2-7540f12884-10c0.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../.yarn/__virtual__/css-loader-virtual-652b6c13f2/2/root/.yarn/berry/cache/css-loader-npm-7.1.2-7540f12884-10c0.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-span-alert {
    color: red;
}

.date-span-warning {
    color: orange;
}

.date-span-normal {
    color: green;
}
`, "",{"version":3,"sources":["webpack://./src/component/DateLabel.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".date-span-alert {\n    color: red;\n}\n\n.date-span-warning {\n    color: orange;\n}\n\n.date-span-normal {\n    color: green;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
