// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-652b6c13f2/2/root/.yarn/berry/cache/css-loader-npm-7.1.2-7540f12884-10c0.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-652b6c13f2/2/root/.yarn/berry/cache/css-loader-npm-7.1.2-7540f12884-10c0.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tile {
    background-color: green;
    color: white;
}

.tile-surcharged {
    background-color: red;
    color: white;
}

.calendar-and-details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.details-table {
    flex: 1;
    margin-left: 20px;
}

@media (max-width: 768px) {
    .calendar-and-details {
        flex-direction: column;
    }

    .details-table {
        margin-left: 0;
        margin-top: 20px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/ClothReport/ClothReport.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,YAAY;AAChB;;AAEA;IACI,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,8BAA8B;AAClC;;AAEA;IACI,OAAO;IACP,iBAAiB;AACrB;;AAEA;IACI;QACI,sBAAsB;IAC1B;;IAEA;QACI,cAAc;QACd,gBAAgB;IACpB;AACJ","sourcesContent":[".tile {\n    background-color: green;\n    color: white;\n}\n\n.tile-surcharged {\n    background-color: red;\n    color: white;\n}\n\n.calendar-and-details {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: space-between;\n}\n\n.details-table {\n    flex: 1;\n    margin-left: 20px;\n}\n\n@media (max-width: 768px) {\n    .calendar-and-details {\n        flex-direction: column;\n    }\n\n    .details-table {\n        margin-left: 0;\n        margin-top: 20px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
