"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const translateRole = (role) => {
    if (role === 'ADMIN') {
        return 'Administrateur';
    }
    else if (role === 'MANAGER') {
        return 'Responsable';
    }
    else if (role === 'MEMBER') {
        return 'Membre';
    }
    return 'Rôle inconnu';
};
exports.default = translateRole;
