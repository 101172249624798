"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const Footer = () => {
    const [versionData, setVersionData] = (0, react_1.useState)({
        environment: "N/C",
        version: "N/C",
    });
    (0, react_1.useEffect)(() => {
        fetch('/api/version/')
            .then((response) => response.json())
            .then((data) => {
            setVersionData(data);
        });
    }, []);
    return ((0, jsx_runtime_1.jsx)(semantic_ui_react_1.Segment, { inverted: true, vertical: true, children: (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Container, { textAlign: "center", children: (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.List, { horizontal: true, inverted: true, divided: true, link: true, size: "small", children: [(0, jsx_runtime_1.jsxs)(semantic_ui_react_1.List.Item, { as: "a", href: "/changelog/", children: ["Pumpkin@", versionData.version, "-", versionData.environment] }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.List.Item, { as: "a", href: "/cgu/", children: "Conditions G\u00E9n\u00E9rales d\u2018Utilisation" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.List.Item, { as: "a", href: "/legals/", children: "Mentions L\u00E9gales" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.List.Item, { as: "a", href: "/privacy/", children: "Politique de Confidentialit\u00E9" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.List.Item, { as: "a", href: "/rat/", children: "Registre des Activit\u00E9s de Traitement" })] }) }) }));
};
exports.default = Footer;
