"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mediaStyles = exports.MediaContextProvider = exports.Media = void 0;
const fresnel_1 = require("@artsy/fresnel");
const AppMedia = (0, fresnel_1.createMedia)({
    breakpoints: {
        mobile: 320,
        tablet: 768,
        computer: 992,
        largeScreen: 1200,
        widescreen: 1920,
    },
});
const mediaStyles = AppMedia.createMediaStyle();
exports.mediaStyles = mediaStyles;
const { Media, MediaContextProvider } = AppMedia;
exports.Media = Media;
exports.MediaContextProvider = MediaContextProvider;
