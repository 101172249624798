"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClothAvailabilityReportObject = void 0;
const zod_1 = require("zod");
exports.ClothAvailabilityReportObject = zod_1.z.record(zod_1.z.string(), // Date as string
zod_1.z.record(zod_1.z.string(), // Item type
zod_1.z.record(zod_1.z.string(), // Size
zod_1.z.object({
    available: zod_1.z.number(),
    pending: zod_1.z.number().nonnegative(),
    out: zod_1.z.number().nonnegative(),
    stock: zod_1.z.number().nonnegative(),
}).strict())));
