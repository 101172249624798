"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const react_router_dom_1 = require("react-router-dom");
const AddAvailabilityModal = ({ open, onClose, addAvailability }) => {
    const { orgId } = (0, react_router_dom_1.useParams)();
    const [startDate, setStartDate] = (0, react_1.useState)(null);
    const [endDate, setEndDate] = (0, react_1.useState)(null);
    const handleAddAvailability = () => {
        if (startDate && endDate) {
            addAvailability({
                type: 'CLOTH_RENTAL',
                start_date: startDate,
                end_date: endDate,
                organization: Number(orgId),
            });
            onClose();
        }
    };
    const isFormValid = () => {
        return startDate !== null && endDate !== null && new Date(startDate) < new Date(endDate);
    };
    return ((0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Modal, { open: open, onClose: onClose, children: [(0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Header, { icon: true, children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Icon, { name: "plus" }), "Ajouter une disponibilit\u00E9"] }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Modal.Content, { children: (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Form, { children: [(0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Form.Field, { children: [(0, jsx_runtime_1.jsx)("label", { children: "Date de d\u00E9but" }), (0, jsx_runtime_1.jsx)("input", { type: "datetime-local", onChange: (e) => setStartDate(e.target.value) })] }), (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Form.Field, { children: [(0, jsx_runtime_1.jsx)("label", { children: "Date de fin" }), (0, jsx_runtime_1.jsx)("input", { type: "datetime-local", onChange: (e) => setEndDate(e.target.value) })] })] }) }), (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Modal.Actions, { children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Button, { color: "black", onClick: onClose, children: "Annuler" }), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Button, { content: "Ajouter", labelPosition: "right", icon: "plus", onClick: handleAddAvailability, positive: true, disabled: !isFormValid() })] })] }));
};
exports.default = AddAvailabilityModal;
